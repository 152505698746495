export interface SitefinityRoute {
  en: {
    // label in the nav, not the page title
    label: string;
    route: string;
  };
  es: {
    label: string;
    route: string;
  };
}

type RouteName =
  | 'home'
  | 'coverage'
  | 'pharmacy'
  | 'findCare'
  | 'premiumBilling'
  | 'claims'
  | 'messages'
  | 'help'
  | 'account'
  | 'helpModal'
  | 'manageAccess'
  | 'languageSettings'
  | 'notificationPreferences'
  | 'changePassword'
  | 'accountForms'
  | 'documentCenter'
  | 'deactivateAccount'
  | 'letters';

export const sitefinityRoutes: Record<RouteName, SitefinityRoute> = {
  home: {
    en: {
      label: 'Home',
      route: '/home',
    },
    es: {
      label: 'Inicio',
      route: '/inicio',
    },
  },
  coverage: {
    en: {
      label: 'Coverage & benefits',
      route: '/coverage',
    },
    es: {
      label: 'Cobertura',
      route: '/cobertura',
    },
  },
  pharmacy: {
    en: {
      label: 'Pharmacy',
      route: '/pharmacy',
    },
    es: {
      label: 'Farmacia',
      route: '/farmacia',
    },
  },
  findCare: {
    en: {
      label: 'Find care',
      route: '/find-care',
    },
    es: {
      label: 'Cuidado',
      route: '/cuidado',
    },
  },
  premiumBilling: {
    en: {
      label: 'Premium billing',
      route: '/premium-billing',
    },
    es: {
      label: 'Facturación',
      route: '/facturación',
    },
  },
  claims: {
    en: {
      label: 'Claims',
      route: '/claims',
    },
    es: {
      label: 'Reclamos',
      route: '/reclamos',
    },
  },
  messages: {
    en: {
      label: 'Messages',
      route: '/messages',
    },
    es: {
      label: 'Mensajes',
      route: '/mensajes',
    },
  },
  account: {
    en: {
      label: 'Account',
      route: '/account',
    },
    es: {
      label: 'Cuenta',
      route: '/cuenta',
    },
  },
  manageAccess: {
    en: {
      label: 'Manage Access',
      route: '/account/manage-access',
    },
    es: {
      label: 'Gestionar acceso',
      route: '/cuenta/gestionar-acceso',
    },
  },
  languageSettings: {
    en: {
      label: 'Language Settings',
      route: '/account/language-settings',
    },
    es: {
      label: 'Configuración de idioma',
      route: '/cuenta/configuración-de-idioma',
    },
  },
  notificationPreferences: {
    en: {
      label: 'Notification Preferences',
      route: '/account/notification-preferences',
    },
    es: {
      label: 'Preferencias de notificaciones',
      route: '/cuenta/preferencias-de-notificaciones',
    },
  },
  changePassword: {
    en: {
      label: 'Change Password',
      route: '/account/change-password',
    },
    es: {
      label: 'Cambiar contraseña',
      route: '/cuenta/cambiar-contraseña',
    },
  },
  help: {
    en: {
      route: '/help',
      label: 'Help',
    },
    es: {
      route: '/help',
      label: 'Help',
    },
  },
  helpModal: {
    en: {
      route: '/help-modal',
      label: 'Help Modal',
    },
    es: {
      route: '/help-modal',
      label: 'Help Modal',
    },
  },
  accountForms: {
    en: {
      label: 'Account forms',
      route: '/account/account-forms',
    },
    es: {
      label: 'Formularios de cuenta',
      route: '/cuenta/formularios-de-cuenta',
    },
  },
  documentCenter: {
    en: {
      label: 'Document Center',
      route: '/account/document-center',
    },
    es: {
      label: 'Centro de documentos',
      route: '/cuenta/centro-de-documentos',
    },
  },
  deactivateAccount: {
    en: {
      label: 'Deactivate account',
      route: '/account/deactivate-account',
    },
    es: {
      label: 'Desactivar cuenta',
      route: '/cuenta/desactivar-cuenta',
    },
  },
  letters: {
    en: {
      label: 'Letters',
      route: '/account/letters',
    },
    es: {
      label: 'Cartas',
      route: '/cuenta/cartas',
    },
  },
};

const getRoute = (route: SitefinityRoute, levelOrdinal: number) => {
  return {
    en: {
      UrlName: route.en.route.substring(1),
      Title: {
        Value: route.en.label,
      },
    },
    es: {
      UrlName: route.es.route.substring(1),
      Title: {
        Value: route.es.label,
      },
    },
    id: `sitefinity-${route.en.label}-id`,
    IsHomePage: route === sitefinityRoutes.home,
    LevelOrdinal: levelOrdinal,
  };
};

export const mainNavRoutes = [
  getRoute(sitefinityRoutes.home, 1),
  getRoute(sitefinityRoutes.coverage, 2),
  getRoute(sitefinityRoutes.findCare, 4),
  getRoute(sitefinityRoutes.claims, 6),
  getRoute(sitefinityRoutes.premiumBilling, 5),
  getRoute(sitefinityRoutes.pharmacy, 3),
];
