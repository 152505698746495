import './NavigationFooter.scss';
import React from 'react';
import { Link } from 'react-router-dom';
import { useHealthTrio } from '../../hooks/useHealthTrio';
import { usePharmacyData } from '../../hooks/usePharmacy';
import useEpicApiStore from '../../store/useEpicApiStore';

type NavigationItem = {
  Url: string;
  TitlesPath: string;
};

type Column = {
  Properties: {
    CssClass: string | null;
    SerializedSelectedPages: string;
  };
};

type ProcessedColumn = {
  name: string | null;
  navigation: NavigationItem[];
};

type Props = {
  columns: Column[] | undefined;
};

const NavigationFooter: React.FC<Props> = ({ columns }) => {
  const processedColumns: ProcessedColumn[] =
    columns?.map((column: Column) => {
      return {
        name: column.Properties.CssClass,
        navigation: JSON.parse(column.Properties.SerializedSelectedPages),
      };
    }) || [];

  return (
    <div className="footer-navigation__row" data-testid="NavigationFooter">
      {processedColumns
        .reverse()
        .map((column: ProcessedColumn, index: number) => (
          <div className="footer-navigation__column" key={index}>
            <h3 className="footer-navigation__column-heading">{column.name}</h3>
            <ul>
              {column.navigation.map((item: NavigationItem, index: number) => (
                <NavigationItemElement
                  column={column}
                  item={item}
                  index={index}
                />
              ))}
            </ul>
          </div>
        ))}
    </div>
  );
};

const NavigationItemElement = (props: {
  column: ProcessedColumn;
  item: NavigationItem;
  index: number;
}) => {
  const pharmacyInfo = usePharmacyData();
  const { isFEHB, isMedicare } = useEpicApiStore();
  const { findHospitalSearch, urgentCareSearch, doctorSearch } =
    useHealthTrio();

  const grievanceFormEn = '/grievance-form';
  const grievanceFormEs = 'formulario-de-queja';
  const grievanceFormUrls = [grievanceFormEn, grievanceFormEs];
  const memberLinks: { [key: string]: string } = {
    'doctor-search': doctorSearch,
    'drug-list': pharmacyInfo.drugListLink || '',
    'urgent-care': urgentCareSearch,
    'find-a-hospital': findHospitalSearch,
  };

  // override grievance form link
  if (isMedicare) {
    memberLinks[grievanceFormEn] =
      'https://www.sharpmedicareadvantage.com/appeals-grievances';
    memberLinks[grievanceFormEs] =
      'https://es.sharpmedicareadvantage.com/appeals-grievances';
  } else if (isFEHB) {
    memberLinks[grievanceFormEn] =
      'https://www.sharphealthplan.com/our-plans/federal-employees-health-benefits/grievance';
    memberLinks[grievanceFormEs] =
      'https://es.sharphealthplan.com/our-plans/federal-employees-health-benefits/grievance';
  }

  // TODO can we refactor this to just check for a matching entry in memberLinks?
  const shouldRenderSimple = (columnName: string | null, url: string) => {
    const renderGrievanceSimple = !isMedicare && !isFEHB;
    return (
      columnName !== 'Get Care' &&
      columnName !== 'Obtener atención' &&
      (renderGrievanceSimple || !grievanceFormUrls.includes(url))
    );
  };

  const renderNavigationItem = (
    columnName: string | null,
    item: NavigationItem,
  ) => {
    if (item.Url === 'drug-list' && !pharmacyInfo.hasPharmacy) {
      return;
    } else if (shouldRenderSimple(columnName, item.Url)) {
      return (
        <Link className="footer-navigation__link" to={item.Url}>
          {item.TitlesPath}
        </Link>
      );
    } else {
      return (
        <Link
          className="footer-navigation__link"
          to={
            memberLinks.hasOwnProperty(item.Url)
              ? memberLinks[item.Url]
              : item.Url
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          {item.TitlesPath}
        </Link>
      );
    }
  };

  return (
    <li key={props.index}>
      {renderNavigationItem(props.column.name, props.item)}
    </li>
  );
};

export default NavigationFooter;
