import './HomeNav.scss';
import { useTranslation } from 'react-i18next';
import HomeButton from '../HomeButton/HomeButton';
import { HomeButtonProps } from '../../interfaces/HomeButton';
import { usePharmacyData } from '../../hooks/usePharmacy';
import { useHealthTrio } from '../../hooks/useHealthTrio';
import useEpicApiStore from '../../store/useEpicApiStore';
import { Provider } from '@shp/shp-epic';

const HomeNav: React.FC = () => {
  const { t } = useTranslation();
  const { isCalpers, isMedicare, isPPO, networkName, provider } =
    useEpicApiStore();
  const pharmacy = usePharmacyData();
  const { urgentCareSearch } = useHealthTrio();
  const homeNavButtons: HomeButtonProps[] = t('home.homeNavButtons', {
    returnObjects: true,
  });

  let urgentCareIcon;

  const filteredHomeNavButtons = homeNavButtons
    .filter((homeNavButton: HomeButtonProps) => {
      if (homeNavButton.link !== '/' + t('routes.pharmacy.index')) {
        return true;
      }

      if (pharmacy?.hasPharmacy) {
        homeNavButton.link = pharmacy?.drugListLink || homeNavButton.link;
        homeNavButton.openInNewTab = true;
        return true;
      }

      return false;
    })
    .filter((homeNavButton: HomeButtonProps) => {
      if (!isPPO) {
        return true;
      }

      if (
        homeNavButton.link === 'urgent-care' ||
        homeNavButton.link === urgentCareSearch
      ) {
        urgentCareIcon = homeNavButton.icon;
        return false;
      }

      if (homeNavButton.link === `/${t('routes.findCare.index')}`) {
        return false;
      }

      return true;
    })
    .filter((homeNavButton: HomeButtonProps) => {
      if (homeNavButton.link !== 'urgent-care') {
        return true;
      }
      homeNavButton.link = urgentCareSearch || homeNavButton.link;
      homeNavButton.openInNewTab = true;
      return urgentCareSearch;
    })
    .filter((homeNavButton: HomeButtonProps) => {
      if (homeNavButton.link === 'patientPortalPlaceholder') {
        const { name: patientPortal, url: patientPortalUrl } =
          Provider.getHealthPortal(
            provider?.pmgName || '',
            isMedicare || false,
            isCalpers || false,
          );
        homeNavButton.label = patientPortal;
        homeNavButton.link = patientPortalUrl;
      }

      return true;
    });

  if (isPPO) {
    filteredHomeNavButtons.splice(2, 0, {
      label: t('home.homeNavTieredButtons.ppoSharp.label').replace(
        '{NETWORK}',
        networkName?.toString() || '',
      ),
      icon: urgentCareIcon,
      link: t('home.homeNavTieredButtons.ppoSharp.link').replace(
        '{NETWORK}',
        networkName?.toString() || '',
      ),
      openInNewTab: true,
    });
    // add to end
    filteredHomeNavButtons.push({
      label: t('home.homeNavTieredButtons.ppoAetna.label') || '',
      icon: urgentCareIcon,
      link: t('home.homeNavTieredButtons.ppoAetna.link'),
      openInNewTab: true,
    });
  }
  // used for ordering buttons on mobile for PPO
  const containerClass = isPPO ? 'home-nav ppo-nav' : 'home-nav';

  return (
    <div className={containerClass} data-testid="HomeNav">
      {filteredHomeNavButtons.map(button => (
        <HomeButton
          key={button.label}
          label={button.label}
          icon={button.icon}
          link={button.link}
          openInNewTab={button.openInNewTab}
        />
      ))}
    </div>
  );
};

export default HomeNav;
