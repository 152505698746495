import './Pharmacy.scss';
import { useTranslation } from 'react-i18next';
import { PrimaryIconLinkProps } from '../../interfaces/PrimaryIconLink';
import TwoColumnCardGrid from '../../components/TwoColumnCardGrid/TwoColumnCardGrid';
import BreadcrumbLayout from '../../layouts/BreadcrumbLayout/BreadcrumbLayout';
import HomeButton from '../../components/HomeButton/HomeButton';
import useEpicApiStore from '../../store/useEpicApiStore';
import PrimaryIconLink from '../../components/PrimaryIconLink/PrimaryIconLink';
import IconBank from '../../components/ShpIcons/IconBank';
import ThreeColumnCards from '../../components/ThreeColumnCards/ThreeColumnCards';
import { CardSecondaryProps } from '../../interfaces/CardSecondary';
import CardSecondary from '../../components/CardSecondary/CardSecondary';
import SpendingBenefits from '../../components/SpendingBenefits/SpendingBenefits';
import ResourceLink from '../../components/ResourceLink/ResourceLink';
import { usePharmacyData } from '../../hooks/usePharmacy';
import { getEpicUrl } from '../../helpers/urlUtils';

const Pharmacy = () => {
  const { t } = useTranslation();
  const { isMedicare, isSHCEGWP, planInfo } = useEpicApiStore();
  const pharmacyData = usePharmacyData();
  const epicUrl = getEpicUrl();
  const buttons: {
    label: string;
    pharmacyDataKey: 'drugListLink' | 'locatorLink';
    icon: string;
    openInNewTab: boolean;
  }[] = t('pharmacy.buttons', {
    returnObjects: true,
  });

  const prescriptionCards: CardSecondaryProps[] = t(
    'pharmacy.prescriptions.cards',
    {
      returnObjects: true,
    },
  );

  prescriptionCards.forEach(card => {
    if (card.link.includes('inside.asp')) {
      card.link = epicUrl + card.link;
    }
    return card;
  });

  const resourceCards: CardSecondaryProps[] = t('pharmacy.resources.cards', {
    returnObjects: true,
  });

  const ctaBlock: PrimaryIconLinkProps = t('pharmacy.ctaBlock', {
    returnObjects: true,
  });
  if (planInfo && planInfo?.productType === 'Medicare Advantage - Individual') {
    ctaBlock.to = 'http://www.mybenefitscenter.com/';
  }

  const spendingBenefitsLinks = [
    {
      text: t('pharmacy.spendingBenefits.buttons.claims.text'),
      to: pharmacyData?.claims || '',
      openInNewTab: JSON.parse(
        t('pharmacy.spendingBenefits.buttons.claims.openInNewTab'),
      ),
    },
    {
      text: t('pharmacy.spendingBenefits.buttons.benefits.text'),
      to: pharmacyData?.benefits || '',
      openInNewTab: JSON.parse(
        t('pharmacy.spendingBenefits.buttons.benefits.openInNewTab'),
      ),
    },
  ];

  return (
    <div data-testid="pharmacy-page">
      <BreadcrumbLayout
        className="pharmacy-page"
        pageTitle={t('pharmacy.pageName')}
      >
        <>
          <div className="pharmacy-page__buttons">
            <div className="container">
              <TwoColumnCardGrid>
                {buttons.map(button => (
                  <HomeButton
                    key={button.label}
                    label={button.label}
                    icon={button.icon}
                    link={pharmacyData[button.pharmacyDataKey] ?? ''}
                    openInNewTab={button.openInNewTab}
                  />
                ))}
              </TwoColumnCardGrid>
            </div>
          </div>
          <div className="container">
            {/* Prescription cards block */}
            <div className="pharmacy-page__prescriptions">
              <ThreeColumnCards>
                {prescriptionCards.map(card => (
                  <CardSecondary key={card.title} {...card} hasHeader={false} />
                ))}
              </ThreeColumnCards>
              <p className="pharmacy-page__prescriptions-disclaimer">
                {t('pharmacy.prescriptions.disclaimer')}
              </p>
            </div>

            {/* Spending and benefits block */}
            <div className="pharmacy-page__spending-benefits">
              <h2 className="pharmacy-page__spending-benefits-heading">
                {t('pharmacy.spendingBenefits.sectionTitle')}
              </h2>
              <SpendingBenefits />

              <TwoColumnCardGrid>
                <>
                  {spendingBenefitsLinks.map((link, index) => (
                    <ResourceLink {...link} key={index} />
                  ))}
                </>
              </TwoColumnCardGrid>
            </div>

            {/* Resources block */}
            <div className="pharmacy-page__resources">
              <h2 className="pharmacy-page__resources-heading">
                {t('wellness.resources.title')}
              </h2>
              <ThreeColumnCards>
                {resourceCards.map(card => (
                  <CardSecondary key={card.title} {...card} />
                ))}
              </ThreeColumnCards>
            </div>

            {/* Over the counter benefit cta block */}
            {!isSHCEGWP && isMedicare && (
              <div className="pharmacy-page__cta-block">
                <PrimaryIconLink {...ctaBlock}>
                  <IconBank />
                </PrimaryIconLink>
              </div>
            )}
          </div>
        </>
      </BreadcrumbLayout>
    </div>
  );
};

export default Pharmacy;
