import './UserStatus.scss';
import { useTranslation } from 'react-i18next';
import useEpicApiStore from '../../store/useEpicApiStore';
import UserStatusIcon from '../UserStatusIcon/UserStatusIcon';
import InformationModal from '../InformationModal/InformationModal';
import { useEffect, useState } from 'react';
import { InfoModalContent } from '../../interfaces/InformationModal';

const UserStatus = () => {
  const { t } = useTranslation();
  const { accountStatus, userDetails } = useEpicApiStore();
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState<InfoModalContent[] | null>(
    null,
  );

  useEffect(() => {
    const content: InfoModalContent[] = t('home.informationModal.content', {
      returnObjects: true,
    });
    Array.isArray(content) && setModalContent(content);
  }, [t]);

  const currentStatusInfo = modalContent
    ? modalContent.filter(content => content.status === accountStatus)
    : [];

  return (
    <div
      className="user-status"
      data-testid="UserStatus"
      aria-label={'User Status'}
    >
      {accountStatus ? (
        <div className="user-status__wrapper">
          <p className="user-status__text">
            <span className="user-status__text-bold">
              {t('user.memberId')}:{' '}
            </span>
            {userDetails?.id}
          </p>
          <p className="user-status__text-status">
            <span className="user-status__text-bold">{t('user.status')}: </span>
            <span
              className={`user-status__pill user-status__pill__status${accountStatus}`}
            >
              {t('user.' + accountStatus)}
              <button
                className="user-status__info"
                onClick={() => {
                  setModalOpen(true);
                }}
              >
                <UserStatusIcon
                  status={accountStatus}
                  title={accountStatus.toString()}
                />
              </button>
            </span>
          </p>
        </div>
      ) : null}

      {isModalOpen && (
        <InformationModal
          title={t('home.informationModal.title')}
          isOpen={isModalOpen}
          setIsOpen={setModalOpen}
        >
          <>
            {currentStatusInfo.map((item, index) => (
              <div key={index}>
                <p style={{ whiteSpace: 'pre-line' }}>
                  <strong>{item.title}</strong>
                  <br />
                  {item.description}
                </p>
                {item.list && (
                  <ul>
                    {item.list.map((listItem, listIndex) => (
                      <li key={listIndex}>{listItem}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
          </>
        </InformationModal>
      )}
    </div>
  );
};

export default UserStatus;
