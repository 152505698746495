import { Page } from '../interfaces/Page';
import { MockData } from './SitefinityMock/MockData';

export const getAllPages = async (locale: string): Promise<Page[]> => {
  return Promise.resolve(MockData.getAllPages(locale));
};

export const getPage = async (url: string, locale: string) => {
  return Promise.resolve(MockData.getPage(url));
};
